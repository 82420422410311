/*  
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}
 
.table {
	&::-webkit-scrollbar {
		width: 10px;
	}
	&::-webkit-scrollbar:horizontal {
		height: 10px;
	}
	&::-webkit-scrollbar-track {
		background-color: transparentize(#ccc, 0.7);
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: transparentize(#ccc, 0.5);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	}
} */
.field-container .custom-input-design{
    border-color: #EBEBEB;

}
.field-container .custom-input-design:hover{
    border-color: #3F7FAE;
}
.group[data-focus=true] .group-data-\[focus\=true\]\:border-default-foreground{
    border-color: #3F7FAE !important;
}
.field-container button {
    max-height: 49px !important;
    
    min-height: 49px;
}

.field-container .group[data-filled=true]  button label {
    font-size: 11px !important;
}

.field-container .group[data-filled=true] button > div {
    padding-top: 10px;
}
.ck-restricted-editing_mode_standard.ck {
    min-height: 310px;
}
table thead tr th {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 40px !important;
    height: auto !important;
    font-size: 16px !important;
    color: #8F8F8F !important;
}
button.popup-btn {
    padding: 0px !important;
    width: auto;
    min-width: auto;
}

.grid-blog {
     align-items: end;
}

.custom-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .radio-mark {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #000;
    margin-right: 8px;
    position: relative;
    transition: background 0.3s;
  }
  
  .radio-mark.checked {
    background: #000;
  }
  
  input[type="radio"]:checked + .radio-mark::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
  }
  